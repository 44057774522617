import { useEffect, useState } from "react";

const Telegram = window.Telegram.WebApp;

let telegramID = null;

Telegram.ready();

Telegram.MainButton.setText('Close');

Telegram.MainButton.onClick(()=>{
    Telegram.close();
})

Telegram.MainButton.show();

Telegram.BackButton.show();

Telegram.BackButton.onClick(()=>{
    Telegram.close();
})

telegramID = Telegram.initDataUnsafe.user.id;

//setError(JSON.stringify(Telegram.initDataUnsafe.user))

Telegram.expand();

export const config = {
    API_URL: 'https://gamify-ba182bbfe52a.herokuapp.com/',
    RPC_URL: 'https://rpc.ankr.com/bsc_testnet_chapel/a73fe89349cfbee583048758151ac46d80a869ef5ddfaf4543cd4e49a707a0cb',
    USER_ID: `${telegramID}`,
}