import { useState, useEffect } from 'react';
import { Card, Form, Col, Row, Button, ListGroup } from "react-bootstrap";
import Web3 from 'web3';
import wof from '../assets/wof.jpeg'
import { config } from '../config';

const web3 = new Web3(new Web3.providers.HttpProvider(config.RPC_URL));

const TOKEN_CONTRACT_ADDRESS = '0xa29a647923b583E628DeF6A92Aa5a84f57A7BF7B';

const Wallets = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [wallet, setWallet] = useState();

    const [privateKey, setPrivateKey] = useState();

    const [ethBalance, setEthBalance] = useState(0);

    const [tokenBalance, setTokenBalance] = useState(null);

    const TOKEN_ABI = [
        {
            "constant": true,
            "inputs": [
                {
                    "name": "_owner",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "name": "balance",
                    "type": "uint256"
                }
            ],
            "type": "function"
        }
    ];

    const loadWallet = async () => {

        const user_id = config.USER_ID;

        const request = await fetch(`${config.API_URL}getWallet?user_id=${user_id}`);

        const response = await request.json();

        setPrivateKey(response[0].private_key)

        setWallet(response[0].wallet_address);

        setIsLoading(false);

    }

    async function getBalance(address) {
        try {
            let balanceWei = await web3.eth.getBalance(`${address}`);
            let balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            setEthBalance(balanceEth)
        } catch (error) {
            console.error('Error fetching balance:', error);
            return null;
        }
    }

    const fetchTokenBalance = async () => {
        const web3 = new Web3(new Web3.providers.HttpProvider(config.RPC_URL));
        const contract = new web3.eth.Contract(TOKEN_ABI, TOKEN_CONTRACT_ADDRESS);

        try {
            const balanceWei = await contract.methods.balanceOf(wallet).call();
            const balance = web3.utils.fromWei(balanceWei, 'ether');
            setTokenBalance(`${balance}`);
        } catch (error) {
            console.error('Error fetching token balance:', error);
        }
    };

    function copyAddress() {
        // Step 1: Retrieve the inner text of the element
        const textToCopy = document.getElementById('walletAddress').innerText;

        // Step 2: Use the Clipboard API to copy the text
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        const Telegram = window.Telegram.WebApp;

        Telegram.showAlert('Address copied to clipboard!');
    }

    function copyPrivateKey() {
        // Step 1: Retrieve the inner text of the element
        const textToCopy = document.getElementById('privateKey').innerText;

        // Step 2: Use the Clipboard API to copy the text
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        const Telegram = window.Telegram.WebApp;

        Telegram.showAlert('Private key copied to clipboard!');
    }

    useEffect(() => {
        loadWallet();
    }, [])

    useEffect(() => {
        if (wallet != "" && wallet != undefined) {
            getBalance(wallet)
            fetchTokenBalance();
        }
    }, [wallet])

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <h4>Manage Wallet</h4>
                        </Card.Body>
                    </Card>
                </div>
            </div>


            <div className="row">
                <div className="col">

                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        {isLoading &&
                            <center>
                                <div>
                                    <div className="loadingio-spinner-eclipse-46i7dk0kdvt">
                                        <div className="ldio-wag2vezkqai">
                                            <div></div>
                                        </div>
                                    </div>
                                    <br />
                                    <span>Loading wallet....</span>
                                </div>
                            </center>
                        }

                        <Card.Body>

                            <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff", padding: 0 }}>
                                <Card.Body style={{ padding: 0 }}>
                                    <ListGroup style={{ backgroundColor: "#ffffff1a" }}>
                                        <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>
                                            Wallet Addresss: <span id="walletAddress">{wallet}</span> <button style={{ borderRadius: 10 }} onClick={() => { copyAddress() }}>Copy</button>
                                        </ListGroup.Item>
                                        <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>Eth Balance: {ethBalance} ETH</ListGroup.Item>
                                        <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>Ludus Balance: {tokenBalance} LUDUS</ListGroup.Item>
                                        <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>Private Key: <span id="privateKey">{privateKey}</span> <button style={{ borderRadius: 10 }} onClick={() => { copyPrivateKey() }}>Copy</button></ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                        </Card.Body>
                    </Card>

                </div>
            </div>

        </div>
    )
}

export default Wallets;