import { useState, useEffect } from 'react';
import { Card, Form, Col, Row, Button, ListGroup } from "react-bootstrap";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { config } from '../config';

const GamesHistory = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [games, setGames] = useState([]);

    const [errorData, setErrorData] = useState("");

    const loadGames = async () => {

        const user_id = config.USER_ID;

        const request = await fetch(`${config.API_URL}getGames?user_id=${user_id}`);

        const response = await request.json();

        setErrorData(JSON.stringify(response));

        setGames(response)

        setIsLoading(false);

    }

    useEffect(() => {
        loadGames();
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <h4>Games History</h4>
                        </Card.Body>
                    </Card>
                </div>
            </div>


            <div className="row">
                <div className="col">

                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        {isLoading &&
                            <center>
                                <div>
                                    <div className="loadingio-spinner-eclipse-46i7dk0kdvt">
                                        <div className="ldio-wag2vezkqai">
                                            <div></div>
                                        </div>
                                    </div>
                                    <br />
                                    <span>Loading wallet....</span>
                                </div>
                            </center>
                        }

                        <Card.Body>

                            {games.map((game) => {
                                const choice1 = game.choice1;
                                const choice2 = game.choice2;
                                const result = game.result;
                                return (
                                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff", padding: 0, marginBottom: 10 }}>
                                        <Card.Body style={{ padding: 0 }}>
                                            <ListGroup style={{ backgroundColor: "#ffffff1a" }}>

                                                <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>
                                                    GameID: {game.game_id}
                                                </ListGroup.Item>

                                                <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>
                                                    Game Status: {game.game_status}
                                                </ListGroup.Item>

                                                <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>
                                                    First Choice: {choice1} Second Choice: {choice2} Result: {result}
                                                </ListGroup.Item>

                                                <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>
                                                    Staked Amount: {game.bet_amount} LUDUS
                                                </ListGroup.Item>

                                                {game.game_status == "pending_play" &&
                                                    <ListGroup.Item style={{ backgroundColor: "#ffffff1a" }}>
                                                        <button style={{ borderRadius: 10 }} onClick={() => {
                                                            navigate({
                                                                pathname: `/Playing${game.name}`,
                                                                search: `?${createSearchParams({ choice: `[${choice1},${choice2}]`, game_id: game.game_id, started: false })}`,
                                                            });
                                                        }}>Play</button>
                                                    </ListGroup.Item>
                                                }

                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                            }

                        </Card.Body>
                    </Card>

                </div>
            </div>

        </div>
    )
}

export default GamesHistory;