import { useState, useEffect } from 'react';
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, createSearchParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import wof from '../assets/wof.jpeg'
import { config } from '../config';

const PlayWOF = () => {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [choice1, setChoice1] = useState("");

    const [choice2, setChoice2] = useState("");

    const [ludusAmount, setLudusAmount] = useState("")

    const showNotify = (type, message, autoClose) => {
        if (type == "error") {
            return toast.error(`${message}`, {
                position: "top-center",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            return toast.success(`${message}`, {
                position: "top-center",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    const playWOF = async () => {

        if (ludusAmount == "" || isNaN(ludusAmount) || ludusAmount == null || choice1 == "" || isNaN(choice1) || choice1 == null || choice2 == "" || isNaN(choice2) || choice2 == null) {

            showNotify("error", "Invalid entry", true);

            return;

        }

        setIsLoading(true);

        const user_id = config.USER_ID;

        try {

            const request = await fetch(`${config.API_URL}playWOF?amount=${ludusAmount}&user_id=${user_id}&choice1=${choice1}&choice2=${choice2}`);

            const response = await request.json();

            if (Object.keys(response).length > 0) {

                if (response.payment_status == "payment_started") {

                    showNotify("success", "Payment is processing, Game will be loaded automatically", false);

                    checkPayment(response.game_id)

                } else if (response.payment_status == "insufficient_error") {

                    showNotify("error", "Insufficient eth or ludus balance, check your wallet!", false)

                } else if (response.payment_status == "insufficient_eth") {

                    showNotify("error", "Insufficient eth, check your wallet!", false)

                }

            } else {

                showNotify("error", "An unknown error occurred", false);

            }

        } catch (e) {

            console.log(e)

            showNotify("error", "An unknown error occurred", false);

        }

    }

    let checkingPayment = false;

    const checkPayment = async (game_id) => {

        if (checkingPayment == false) {

            checkingPayment = true;

            const request = await fetch(`${config.API_URL}checkGamePayment?game_id=${game_id}`);

            const response = await request.json();

            checkingPayment = false;

            if (response.feedback == "paid") {
                setIsLoading(false)
                localStorage.setItem(`${game_id}`, true);
                navigate({
                    pathname: "/PlayingWOF",
                    search: `?${createSearchParams({ choice: `[${choice1},${choice2}]`, game_id: game_id, started: false })}`,
                });
                return;
            }

        }

        setTimeout(() => { checkPayment(game_id) }, 2000)

    }

    useEffect(() => {
    }, [])

    return (
        <div className="container">

            <ToastContainer />

            <div className="row">
                <div className="col">
                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <h4>Play Wheel of Fortune</h4>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col">

                    <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                        <Card.Body>
                            <h4>How to play</h4>

                            <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                                <Card.Body>
                                    <img src={wof} style={{ height: 200, borderRadius: 10, clear: "both" }} />
                                    <p></p>
                                    <p>
                                        Select amount of ludus tokens to stake.
                                    </p>
                                    <p>
                                        Choose a number between 1 and 10 for choice 1 and choice 2
                                    </p>
                                    <p>
                                        Click on continue to play.
                                    </p>
                                </Card.Body>
                            </Card>
                            {isLoading &&
                                <center>
                                    <div>
                                        <div className="loadingio-spinner-eclipse-46i7dk0kdvt">
                                            <div className="ldio-wag2vezkqai">
                                                <div></div>
                                            </div>
                                        </div>
                                        <br />
                                        <span>Loading, please wait....</span>
                                    </div>
                                </center>
                            }
                            <p></p>

                            {!isLoading &&
                                <>
                                    <Row style={{ marginBottom: 23 }}>
                                        <Col>
                                            <Form.Label column sm={2}>
                                                Bet Amount <span style={{ fontSize: "10px" }}>(LUDUS)</span>
                                            </Form.Label>
                                            <Col sm={2}>
                                                <Form.Control type="number" placeholder="Amount" onChange={(e) => {
                                                    setLudusAmount(e.target.value)
                                                }}
                                                    value={ludusAmount}
                                                />
                                            </Col>
                                        </Col>
                                        <Col>
                                            <Form.Label column sm={2}>
                                                Choice 1
                                            </Form.Label>
                                            <Col sm={2} style={{ marginTop: 23 }}>
                                                <Form.Select onChange={(e) => {
                                                    setChoice1(e.target.value);
                                                }}
                                                    value={choice1}
                                                >
                                                    <option>Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </Form.Select>
                                            </Col>
                                        </Col>
                                        <Col>
                                            <Form.Label column sm={2}>
                                                Choice 2
                                            </Form.Label>
                                            <Col sm={2} style={{ marginTop: 23 }}>
                                                <Form.Select onChange={(e) => {
                                                    setChoice2(e.target.value)
                                                }}
                                                    value={choice2}
                                                >
                                                    <option>Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </Form.Select>
                                            </Col>
                                        </Col>
                                    </Row>

                                    <div style={{marginBottom:30}}>
                                        <Button variant="secondary" onClick={() => {
                                            playWOF();
                                        }}>Continue</Button>
                                    </div>
                                </>
                            }

                        </Card.Body>
                    </Card>

                </div>
            </div>

        </div >
    )
}

export default PlayWOF;