import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import PlayWOF from './pages/PlayWOF'
import PlayDICE from './pages/PlayDICE'
import PlayingWOF from './pages/PlayingWOF'
import PlayingDICE from './pages/PlayingDICE'
import Wallets from './pages/Wallets';
import reportWebVitals from './reportWebVitals';
import GamesHistory from './pages/GameHistory';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
  {
    path: "/wallets",
    element: <Wallets />
  },
  {
    path: "/games",
    element: <GamesHistory />
  },
  {
    path: "/PlayWOF",
    element: <PlayWOF />
  },
  {
    path: "/PlayingWOF",
    element: <PlayingWOF />
  },
  {
    path: "/PlayDICE",
    element: <PlayDICE />
  },
  {
    path: "/PlayingDICE",
    element: <PlayingDICE />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
