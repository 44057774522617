import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { config } from '../config';
import './DiceRollGame.css';

const PlayingDice = () => {

    const navigate = useNavigate();

    const [isGameOver, setIsGameOver] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);

    // To get the value of a specific parameter
    const userChoice = urlParams.get('choice'); // "value"

    const game_id = urlParams.get('game_id');

    const [gameIDD,setGameIDD] = useState(game_id)

    const authentic = localStorage.getItem(`${game_id}`);

    const [result, setResult] = useState(0);

    const [loggedResult, setLoggedResult] = useState(false);

    let [count, setCount] = useState(0);

    useEffect(() => {

        if (game_id == "" || game_id == null) {
            window.location.href = "PlayDICE";
        }

        if (authentic == null) {
            window.location.href = "PlayDICE";
        }

        function rollDicee() {

            localStorage.removeItem(`${game_id}`);

            //console.log(count)

            if (count >= 20) return; // If you only want to roll 10 times

            const diceRoll = Math.floor(Math.random() * 6) + 1;
            const elDiceOne = document.getElementById('dice1'); // assuming you have a ref to your dice element

            for (let i = 1; i <= 6; i++) {
                elDiceOne.classList.remove('show-' + i);
                if (diceRoll === i) {
                    elDiceOne.classList.add(`show-${i}`);
                    setResult(diceRoll);
                }
            }

            setCount(count += 1);

            setTimeout(() => {
                rollDicee();
            }, 400)
        }

        document.getElementById("rollDice").addEventListener("click", () => {
            rollDicee();
        })

    }, [])

    const uploadResult = async (game_id, user_id, result) => {

        const request = await fetch(`${config.API_URL}gameOver?game_id=${game_id}&user_id=${user_id}&result=${result}`);

        //const response = await request.json();

        //console.log(response);

    }

    useEffect(() => {

        if (count === 20 && !loggedResult) {

            setTimeout(() => {
                if (userChoice.includes(result) && count === 20 && loggedResult === false) {
                    console.log("You have won! Congratulations! ");
                    document.getElementById("result").textContent = "You have won! Congratulations! Your reward will be sent shortly";
                } else {
                    document.getElementById("result").textContent = "You lost, play again!";
                }

                uploadResult(gameIDD, config.USER_ID, result);
            }, 2000);

            setIsGameOver(true);

            setLoggedResult(true);
        }

        console.log(`The result is ${result}<=>${count}<=>${loggedResult}`);
    }, [result, count, loggedResult]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                            <Card.Body>
                                <center>
                                    <h4>Playing Roll a Dice</h4>
                                </center>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className="row">
                    <div className="col">

                        <Card className="shadow-lg" style={{ backgroundColor: "#ffffff1a", color: "#fff" }}>
                            <Card.Body>

                                <div style={{ margin: 20 }}>
                                    <center>
                                        <div style={{margin:10, marginBottom:30}}>
                                            <Row>
                                                <Col>First Choice: {userChoice[1]}</Col>
                                                <Col>Second Choice: {userChoice[3]}</Col>
                                            </Row>
                                        </div>

                                        <div id='dice1' className="dice dice-one">

                                            <div id="dice-one-side-one" className='side one'>
                                                <div className="dot one-1"></div>
                                            </div>
                                            <div id="dice-one-side-two" className='side two'>
                                                <div className="dot two-1"></div>
                                                <div className="dot two-2"></div>
                                            </div>
                                            <div id="dice-one-side-three" className='side three'>
                                                <div className="dot three-1"></div>
                                                <div className="dot three-2"></div>
                                                <div className="dot three-3"></div>
                                            </div>
                                            <div id="dice-one-side-four" className='side four'>
                                                <div className="dot four-1"></div>
                                                <div className="dot four-2"></div>
                                                <div className="dot four-3"></div>
                                                <div className="dot four-4"></div>
                                            </div>
                                            <div id="dice-one-side-five" className='side five'>
                                                <div className="dot five-1"></div>
                                                <div className="dot five-2"></div>
                                                <div className="dot five-3"></div>
                                                <div className="dot five-4"></div>
                                                <div className="dot five-5"></div>
                                            </div>
                                            <div id="dice-one-side-six" className='side six'>
                                                <div className="dot six-1"></div>
                                                <div className="dot six-2"></div>
                                                <div className="dot six-3"></div>
                                                <div className="dot six-4"></div>
                                                <div className="dot six-5"></div>
                                                <div className="dot six-6"></div>
                                            </div>

                                        </div>

                                    </center>
                                </div>
                                <div style={{ marginBottom: "20px" }}>
                                    <center>
                                        <div id="result"></div>
                                    </center>
                                </div>
                                <div>
                                    {!isGameOver &&
                                        <center>
                                            <Button variant="secondary" id="rollDice" >Roll Dice</Button>
                                        </center>
                                    }
                                    {isGameOver &&
                                        <center>
                                            <Button variant="secondary" onClick={() => {
                                                navigate("/PlayDICE")
                                            }}>Play Again</Button>
                                        </center>
                                    }
                                </div>


                            </Card.Body>
                        </Card>

                    </div>
                </div>

            </div>
        </>
    );
};

export default PlayingDice;
